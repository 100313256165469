import * as yup from "yup";
import cn from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useHistory, useLocation, useParams } from "react-router-dom";
import config from "../constants";

import { useCopy } from "../providers/CopyProvider";
import Header from "../components/Header";
import Button from "../components/Button";
import useLogin from "../hooks/useLogin";

import Error from "../icons/error.svg";

import "./Login.scss";

type FormData = {
  email: string;
  password: string;
};

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

const FORGOT_PASSWORD_URL =
  "https://account.napster.com/myacct/forgotpassworddefault.html";

function Login() {
  const params = useParams<{
    [key: number]:
      | "samsung"
      | "lg"
      | "firetv"
      | "xbox"
      | "steamdeck"
      | "chery"
      | "lg-auto";
  }>();
  const location = useLocation();
  const history = useHistory();
  const copy = useCopy();
  const { error, execute: login } = useLogin();

  const { register, handleSubmit, formState } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const platform = params[0];

  const { errors, isSubmitting, isDirty } = formState;

  const handleLogin = async ({ email, password }: FormData) => {
    const resp = await login(email, password);
    if (resp) {
      history.push(`/${platform}/pair${location.search}`);
    }
  };

  return (
    <>
      <Header title={copy("login.title")} />
      <form
        data-testid="login-form"
        className="loginForm"
        onSubmit={handleSubmit(handleLogin)}
      >
        {error && (
          <div className="loginForm__error">
            <p>{error}</p>
          </div>
        )}

        <div className="input-wrapper">
          <input
            className={cn("input loginForm__input", {
              "input--error": errors?.email?.message,
            })}
            placeholder={copy("login.email-placeholder")}
            type="text"
            {...register("email")}
          />
          {errors?.email?.message && (
            <div className="input-error">
              <img src={Error} alt={copy("errors.input-error")} />
              {errors?.email?.message}
            </div>
          )}
        </div>

        <div className="input-wrapper">
          <input
            className={cn("input loginForm__input", {
              "input--error": errors?.password?.message,
            })}
            placeholder={copy("login.password-placeholder")}
            type="password"
            autoComplete="on"
            {...register("password")}
          />
          {errors?.password?.message && (
            <div className="input-error">
              <img src={Error} alt={copy("errors.input-error")} />
              {errors?.password?.message}
            </div>
          )}
        </div>
        <Button
          label={copy("login.login-button")}
          loading={isSubmitting}
          disabled={!isDirty}
        />
      </form>
      <p className="text text--center forgotPassword">
        <a href={FORGOT_PASSWORD_URL} target="_blank" rel="noreferrer">
          {copy("login.forgot-password")}
        </a>
      </p>
      <p className="text text--center signUp">
        {copy("login.need-account")}{" "}
        <a
          href={config.signupUrl[platform] || "#"}
          target="_blank"
          rel="noreferrer"
        >
          {copy("login.sign-up")}
        </a>
      </p>
    </>
  );
}

export default Login;
