import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import UserProvider from "./providers/UserProvider";
import { CopyProvider } from "./providers/CopyProvider";

ReactDOM.render(
  <React.StrictMode>
    <CopyProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </CopyProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
