import cn from "classnames";

import "./Button.scss";

interface ButtonProps {
  label: string;
  loading: boolean;
  disabled: boolean;
}

export default function Button({ label, loading, disabled }: ButtonProps) {
  return (
    <button
      className={cn("button", {
        "button--loading": loading,
        "button--disabled": disabled,
      })}
      disabled={disabled || loading}
    >
      {label}
    </button>
  );
}
