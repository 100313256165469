import "./Loading.scss";

export default function Loading() {
  return (
    <div className="loading-screen">
      <svg
        width="72"
        height="72"
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M71.1958 43.5667C69.7186 50.4376 66.2628 56.7257 61.2543 61.6558C56.2458 66.5859 49.9038 69.9422 43.0105 71.3109C36.1173 72.6794 28.9741 72.0004 22.4621 69.3576C15.9502 66.7147 10.3541 62.2237 6.36421 56.4383C2.37432 50.6528 0.16505 43.826 0.00889139 36.8C-0.147268 29.7738 1.75651 22.8557 5.48543 16.8987C9.21434 10.9417 14.6053 6.20644 20.9934 3.2769C27.3816 0.347348 34.4875 -0.648345 41.4348 0.412622L40.6688 5.42813C34.7007 4.51669 28.5962 5.37206 23.1084 7.88872C17.6206 10.4054 12.9893 14.4733 9.786 19.5907C6.58262 24.7082 4.94715 30.6513 5.0813 36.6872C5.21545 42.723 7.11335 48.5877 10.5409 53.5577C13.9685 58.5278 18.7759 62.386 24.3701 64.6563C29.9643 66.9267 36.1007 67.5101 42.0225 66.3343C47.9443 65.1586 53.3925 62.2753 57.695 58.04C61.9977 53.8049 64.9665 48.4028 66.2355 42.5003L71.1958 43.5667Z"
          fill="white"
        />
      </svg>
    </div>
  );
}
