import { useEffect, useMemo, useState } from "react";
import qs from "qs";
import cn from "classnames";
import { useLocation, useHistory } from "react-router-dom";

import Header from "../components/Header";
import useActivate from "../hooks/useActivate";
import Loading from "../components/Loading";
import { useCopy } from "../providers/CopyProvider";

import "./Pair.scss";

const MAX_LENGTH = 6;

function Pair() {
  const location = useLocation();
  const history = useHistory();
  const copy = useCopy();
  const [code, setCode] = useState("");
  const { execute: activate, loading, error: activateError } = useActivate();

  const activeIndex = code.length;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Backspace") {
        setCode((x) => x.slice(0, x.length - 1));
        return;
      }

      const numbers = Array(10)
        .fill(0)
        .map((_, i) => i.toString());

      if (numbers.includes(event.key)) {
        setCode((x) => (x.length >= MAX_LENGTH ? x : x + event.key.toString()));
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    function handleClick() {
      const el = document.getElementById("trigger-open-keyboard");
      if (el) {
        el.focus();
      }
    }

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    if (code.length >= 6) {
      activate(code)
        .then(() => {
          history.push(`/success`);
        })
        .catch((e) => setCode(""));
    }
  }, [activate, code, history]);

  useEffect(() => {
    const params = qs.parse(location.search?.replace("?", ""));
    if (params.userCode && params.userCode.length === 6) {
      setCode(params.userCode.toString());
    }
  }, [location.search]);

  return (
    <>
      {loading && <Loading />}
      <Header title={copy("pair.enter-pin")} />
      <div data-testid="pair" className="pair">
        <p className="title title--center">{copy("pair.title")}</p>
        <div className="pair__inputs">
          <input id="trigger-open-keyboard" />
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <input
                key={i}
                disabled
                value={code.slice(i, i + 1)}
                className={cn("input pair__inputs__input", {
                  "pair__inputs__input--active": activeIndex === i,
                })}
              />
            ))}
        </div>
        <p className="text text--muted text--center">
          {copy("pair.description")}
        </p>
        {activateError && (
          <p className="text text--muted text--center error">{activateError}</p>
        )}
        <p className="text help">
          <a href="https://help.napster.com/" target="_blank" rel="noreferrer">
            {copy("pair.help")}
          </a>
        </p>
      </div>
    </>
  );
}

export default Pair;
