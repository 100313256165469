import { HashRouter as Router, Switch, Route } from "react-router-dom";

import Pair from "./views/Pair";
import Success from "./views/Success";
import Login from "./views/Login";
import Error404 from "./views/404";
import { useUser } from "./providers/UserProvider";

function App() {
  const { user } = useUser();

  const isLoggedIn = !!user?.access_token;

  const platformRegex =
    "(samsung|lg|browser|xbox|firetv|steamdeck|chery|lg-auto)";
  return (
    <div className="app">
      <Router>
        <Switch>
          {isLoggedIn && (
            <>
              <Route exact path="/success" component={Success} />
              <Route exact path={`/${platformRegex}/pair`} component={Pair} />
            </>
          )}

          <Route exact path={`/${platformRegex}`} component={Login} />
          <Route component={Error404} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
