import Header from "../components/Header";
import { useCopy } from "../providers/CopyProvider";

import "./Success.scss";

function Success() {
  const copy = useCopy();
  return (
    <>
      <Header title={copy("success.title")} />

      <div className="success">
        <svg
          className="tick-circle"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30 53.3333C42.8866 53.3333 53.3333 42.8866 53.3333 30C53.3333 17.1134 42.8866 6.66667 30 6.66667C17.1134 6.66667 6.66667 17.1134 6.66667 30C6.66667 42.8866 17.1134 53.3333 30 53.3333ZM30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z"
            fill="#DAEC31"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.8256 18.351L44.5396 23.065L24.4048 43.1998L13.1589 31.9539L17.873 27.2399L24.4048 33.7717L39.8256 18.351Z"
            fill="#DAEC31"
          />
        </svg>

        <p className="text text--muted text--center">
          {copy("success.description")}
        </p>
        <p className="text help">
          <a href="https://help.napster.com/" target="_blank" rel="noreferrer">
            {copy("success.help")}
          </a>
        </p>
      </div>
    </>
  );
}

export default Success;
