import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import config, { ApiKeys } from "../constants";
import { useCopy } from "../providers/CopyProvider";
import { useUser } from "../providers/UserProvider";

export default function useLogin() {
  const { setUser } = useUser();
  const copy = useCopy();
  const [error, setError] = useState("");
  const params = useParams<{ [key: number]: keyof ApiKeys }>();
  const errorText = copy("errors.login");
  const platform = params[0];

  const execute = useCallback(
    async (username, password) => {
      try {
        if (!platform) {
          throw errorText;
        }

        const apiKeys = config.apiKeys[platform];

        if (!apiKeys) {
          throw errorText;
        }

        const resp = await fetch(config.napiBaseUri + "/oauth/token", {
          method: "POST",
          body: JSON.stringify({ grant_type: "password", username, password }),
          headers: {
            Authorization: `Basic ${btoa(
              `${apiKeys.napi}:${apiKeys.napiSecret}`
            )}`,
            "Content-Type": "application/json",
          },
        });

        if (resp.status !== 200) {
          throw errorText;
        }

        const json = await resp.json();
        setUser(json);
        return json;
      } catch (error) {
        setError(error as string);
        return false;
      }
    },
    [errorText, platform, setUser]
  );

  return { execute, error };
}
