import { createContext, useState, useContext } from "react";

interface IUserContext {
  setUser?: any;
  user?: any;
}

const UserContext = createContext<IUserContext>({});

export const useUser = () => useContext(UserContext);

export default function UserProvider({ children }: any) {
  const [user, setUser] = useState({});

  return (
    <UserContext.Provider value={{ setUser, user }}>
      {children}
    </UserContext.Provider>
  );
}
