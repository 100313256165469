import Logo from "../assets/logo.svg";

import { useCopy } from "../providers/CopyProvider";

import "./Header.scss";

interface HeaderProps {
  title: string;
}

export default function Header({ title }: HeaderProps) {
  const copy = useCopy();
  return (
    <header className="header">
      <img className="header__logo" src={Logo} alt={copy("header.logo")} />
      <h1 className="header__title">{title}</h1>
    </header>
  );
}
