import get from "lodash/get";
import {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";

import config from "../constants";
import { default as defaultCopy } from "../copy/en.json";

export const defaultLanguage = "en";

const availableLanguages = [
  "en",
  "en-GB",
  "da",
  "de",
  "el",
  "es",
  "es-419", //(es-LA)
  "fr",
  "fr-CA",
  "it",
  "nl",
  "pt",
  "pt-BR",
];

export const getDeviceLocale = (): {
  supportedLocale: string;
  baseLocale: string;
} => {
  const { languages, language } = window.navigator;
  const locale = languages?.[0] || language;
  const localeParts = locale.split("-");
  const isBaseLanguageSupported = availableLanguages.includes(localeParts[0]);
  const [baseLocale] = localeParts;
  let supportedLocale = baseLocale;

  if (!isBaseLanguageSupported)
    return { supportedLocale: defaultLanguage, baseLocale: defaultLanguage };

  // if the full locale is supported (pt-BR) return that. Otherwise
  // just return the base language (pt)
  if (availableLanguages.includes(locale)) supportedLocale = locale;

  return { supportedLocale, baseLocale };
};

const CopyContext = createContext<object | undefined>({});

export const useCopy = () => {
  const copy = useContext(CopyContext);

  return useCallback(
    (path: string, replaces?: Array<string>) => {
      let resolvedCopy: string = copy ? get(copy, path) : "";
      if (resolvedCopy && replaces) {
        replaces.forEach((replace: string, i: number) => {
          resolvedCopy = resolvedCopy.replace(`$${i + 1}`, replace);
        });
      }
      return resolvedCopy;
    },
    [copy]
  );
};

/**
 * Fetch copy from remove provider
 * For now we just have some JSON files in the `public` folder. This may be
 * all we need for a long term solution because we really don't have much copy
 * in our app.
 *
 * @param {String} locale
 * @returns {Object} copy object
 */
const fetchCopy = async () => {
  const { supportedLocale } = getDeviceLocale();

  if (supportedLocale === defaultLanguage) {
    return defaultCopy;
  } else {
    try {
      const externalUrl = config.externalFolder;
      const resp = await fetch(`${externalUrl}/copy/${supportedLocale}.json`);

      if (!resp) {
        return defaultCopy;
      }
      const data = await resp.json();
      return data;
    } catch (_) {
      return defaultCopy;
    }
  }
};

export function CopyProvider({ children }: any) {
  const [copy, setCopy] = useState();

  useEffect(() => {
    async function loadCopy() {
      const copy = await fetchCopy();
      if (!copy) {
        return;
      }
      setCopy(copy);
    }
    loadCopy();
  }, []);

  return <CopyContext.Provider value={copy}>{children}</CopyContext.Provider>;
}
