import { useCopy } from "../providers/CopyProvider";

import "./404.scss";

function Error404() {
  const copy = useCopy();
  return <>404 {copy("errors.error")}</>;
}

export default Error404;
