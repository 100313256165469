import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import config, { ApiKeys } from "../constants";
import { useCopy } from "../providers/CopyProvider";
import { useUser } from "../providers/UserProvider";

export default function useActivate() {
  const { user } = useUser();
  const copy = useCopy();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const params = useParams<{ [key: number]: keyof ApiKeys }>();
  const errorLogin = copy("errors.login");
  const errorActivate = copy("errors.activate");

  const platform = params[0];

  const execute = useCallback(
    async (userCode) => {
      try {
        setLoading(true);
        setError("");

        if (!platform) {
          return;
        }

        const apiKeys = config.apiKeys[platform];

        if (!apiKeys) {
          setError(errorLogin);
          return;
        }

        const resp = await fetch(
          config.napiBaseUri + "v3/oauth/device/activate",
          {
            method: "POST",
            body: JSON.stringify({
              client_id: apiKeys.napiAppId,
              user_code: `${userCode.slice(0, 3)}-${userCode.slice(-3)}`,
            }),
            headers: {
              Authorization: "Bearer " + user.access_token,
              "Content-Type": "application/json",
            },
          }
        );

        if (resp.status !== 200 && resp.status !== 204) {
          setError(errorActivate);
          throw new Error(errorActivate);
        }

        return resp.body;
      } catch (_) {
        setError(errorActivate);
        throw new Error(errorActivate);
      } finally {
        setLoading(false);
      }
    },
    [errorActivate, errorLogin, platform, user?.access_token]
  );

  return { execute, error, loading };
}
